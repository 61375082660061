import React from 'react';
import { Navigate } from 'react-router-dom';
import ErrorPage from '../shared/components/ErrorPage';
import NotFoundPage from '../shared/components/NotFoundPage';

const AChatBot = React.lazy(() => import('./AChatBot'));
const AHomePage = React.lazy(() => import('./AHomePage'));
const AOneUser = React.lazy(() => import('./AOneUser'));
const AUsers = React.lazy(() => import('./AUsers'));
const AChatBotLogin = React.lazy(() => import('./AChatBotLogin'));

export const routes = [
  {
    index: true,
    element: <AHomePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'home',
    element: <Navigate to="/admin" />,
  },
  {
    path: 'users',
    element: <AUsers />,
  },
  {
    path: 'users/:userId',
    element: <AOneUser />,
  },
  {
    path: 'chatbot',
    element: <AChatBot />,
  },
  {
    path: 'chatbot/login',
    element: <AChatBotLogin />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
];
